.traduction input[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.traduction label {
  overflow: hidden;
  --width-toggler: 60px;
  --height-toggler: 30px;

  position: relative;
  width: var(--width-toggler);
  height: var(--height-toggler);

  border-radius: 25px;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 9px;
  background-color: #9858317e;
}

.traduction label:after {
  background-color: var(--primary);
  content: '';
  position: absolute;
  top: 0px;
  left: -25px;

  width: calc(var(--width-toggler) - 3px);
  height: 100%;
  aspect-ratio: 1;
  border-radius: 25px;
  transition-duration: 0.3s;
}

.traduction input:checked + label {
  /* background-color: var(--primary); */
  direction: rtl;
}

.traduction input:checked + label:after {
  left: calc(100% + 25px);
  transform: translateX(-100%);
}

.traduction label span {
  position: relative;
  z-index: 2;
  font-weight: 600;
  font-size: 15px;
  color: #fff;
}
