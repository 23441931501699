header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 999;
}
html[lang='fr'] header {
  direction: ltr;
}

header .logo img {
  height: 90px;
}

header .logo img.mobile {
  display: none;
}

header > .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header menu ul {
  display: flex;
  list-style: none;
  gap: 20px;
}
html[lang='fr'] header menu ul {
  gap: 13px;
}

header menu ul li {
  font-weight: 500;
  cursor: pointer;
  position: relative;
  padding: 5px 0;
}

header menu ul li:hover {
  color: var(--primary);
}
header menu ul li:hover .submenu {
  color: black;
}

header menu ul li:hover .submenu {
  /* color: var(--primary); */
  display: block;
}

header menu ul li::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 0;
  /* background-color: var(--primary); */
}

header menu ul li:hover::after {
  transition: 0.4s all;
  width: 100%;
}

.mobile-menu {
  display: none;
  border: none;
  outline: none;
  background: transparent;
  transition: all 0.4s;
}

.submenu {
  position: absolute;
  z-index: 99;
  display: none;
  top: 0px;
  padding-top: 50px;
}

.submenu ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  padding: 10px 20px;
  position: relative;
  border-right: solid 4px var(--primary);
  box-shadow: 0 10px 20px #0005;
}

html[lang='fr'] .submenu ul {
  border-right: 0;
  border-left: solid 4px var(--primary);
}

.submenu ul li {
  display: block;
  white-space: nowrap;
}

.submenu ul li:hover {
  color: var(--primary);
}

.submenu ul::before {
  border: none;
  border: 15px solid transparent;
  border-bottom-color: var(--primary);
  height: 0px;
  width: 0px;
  content: '';
  display: block;
  position: absolute;
  top: -28px;
}

menu ul li .submenu ul li {
  font-weight: 400;
}
menu ul li .submenu ul li .submenu {
  display: none;
  padding-top: 0;
  margin-right: 100%;
}
html[lang='fr'] menu ul li .submenu ul li .submenu {
  margin-right: 0;
  margin-left: 100%;
}

menu ul li .submenu ul li .submenu ul::before {
  border: none;
}
menu ul li .submenu ul li:hover .submenu {
  display: block;
}
html[lang='fr'] menu ul li {
  text-align: center;
  font-size: 15px;
}
html[lang='fr'] menu ul li .submenu li,
html[lang='fr'] menu ul li .submenu li a {
  text-align: left;
}

@media (max-width: 1100px) {
  header .logo img {
    display: none;
  }
  header .logo img.mobile {
    display: block;
    height: 60px;
    padding: 10px 0;
  }
  header menu {
    display: flex;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    align-items: center;
    z-index: 9;
    transform: translateX(100vw);
    transition: all 0.4s;
  }

  html[lang='fr'] header menu {
    transform: translateX(-100vw);
  }
  .mobile-menu {
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
  }
  .mobile-menu.active {
    position: fixed;
    right: 40px;
    top: 40px;
    z-index: 99;
  }
  html[lang='fr'] .mobile-menu.active {
    left: 40px;
    right: 40px;
  }
  .mobile-menu .bar {
    height: 0px;
    width: 30px;
    border-bottom: solid 2px black;
    transition: all 0.4s;
  }
  .mobile-menu.active .bar:first-child {
    transform: translateY(9.5px) rotate(45deg);
  }
  .mobile-menu.active .bar:last-child {
    transform: translateY(-9.5px) rotate(-45deg);
  }
  .mobile-menu.active .bar:nth-child(2) {
    transform: rotateY(90deg);
  }
  .mobile-menu.active ~ menu {
    transform: translateX(0);
  }

  header menu ul {
    background-color: white;
    flex-direction: column;
    /* justify-content: center; */
    padding: 40px;
    align-items: flex-start;
    max-width: 320px;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 9;
    transform: translateX(100%);
    padding-right: 22px;
    padding-top: 100px;
    overflow: auto;
    visibility: hidden;
  }

  header menu ul::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  header menu ul::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  header menu ul::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  header menu ul::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .mobile-menu.active ~ menu ul {
    transform: translateX(0);
    visibility: visible;
  }
  .mobile-menu.active ~ menu .overlay {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #0005;
  }
  .submenu {
    position: static;
    padding-top: 0;
    margin-top: 20px;
  }
  .submenu ul {
    box-shadow: none;
    padding-top: 0;
    padding-bottom: 0;
  }
  .submenu ul::before {
    display: none;
  }

  menu ul li .submenu ul li .submenu {
    margin-right: 5px;
  }
  html[lang='fr'] menu ul li .submenu ul li .submenu {
    margin-left: 0;
  }
  menu ul li .submenu ul li,
  html[lang='fr'] menu ul li .submenu li {
    text-wrap: pretty;
  }
  html[lang='fr'] header menu ul {
    border-left: solid 4px var(--primary);
    border-right: none;
    text-align: left;
  }
}

@media (max-width: 800px) {
  header button.btn {
    transform: translateX(100vw);
    z-index: 9;
    position: fixed;
  }
  .mobile-menu.active ~ button.btn {
    transition: all 0.4s;
    right: 40px;
    bottom: 40px;
    width: calc(300px - 80px);
    text-align: center;
    display: block;
    transform: translateX(0);
  }
}
