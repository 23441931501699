:root {
  --primary: #985931;
  --white: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Tajawal', sans-serif;
  font-weight: 400;
  /* text-align: right; */
}

a {
  text-decoration: none;
  color: currentColor;
}

body {
  direction: rtl;
}

section {
  overflow: hidden;
}

.btn {
  padding: 8px 16px;
  background-color: var(--primary);
  border: none;
  outline: none;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
  cursor: pointer;
}

.btn:hover {
  background-color: var(--white);
  color: var(--primary);
  border: solid 1px var(--primary);
}

.container {
  max-width: 1400px;
  padding: 0 4vw;
  margin: auto;
}

.tajawal-extralight {
  font-family: 'Tajawal', sans-serif;
  font-weight: 200;
  font-style: normal;
}

.tajawal-light {
  font-family: 'Tajawal', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.tajawal-regular {
  font-family: 'Tajawal', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.tajawal-medium {
  font-family: 'Tajawal', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.tajawal-bold {
  font-family: 'Tajawal', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.tajawal-extrabold {
  font-family: 'Tajawal', sans-serif;
  font-weight: 800;
  font-style: normal;
}

.tajawal-black {
  font-family: 'Tajawal', sans-serif;
  font-weight: 900;
  font-style: normal;
}

.top {
  background-color: var(--primary);
  width: 100%;
  overflow: hidden;
  box-sizing: content-box;
}

.top .ticker {
  --speed: 90s;

  height: 40px;

  display: inline-block;
  margin: auto 0;

  box-sizing: content-box;
  padding-right: 100%;

  flex-wrap: nowrap;
  white-space: nowrap;
  animation: scroll-rtl var(--speed) 0s linear infinite;
}
html[lang='fr'] .top .ticker {
  padding-right: 0;
  padding-left: 100%;
}
.top .ticker p {
  text-wrap: nowrap;
  color: white;
  white-space: nowrap;
  font-size: 14px;

  display: inline-block;
  padding: 0 35px;

  transform: translate3d(0, 30%, 0);
}
.top .ticker p:hover a {
  text-decoration: underline;
}
html[lang='fr'] .top {
  direction: ltr;
}
html[lang='fr'] .top .ticker {
  /* --items-l: 1; */
  animation: scroll-ltr var(--speed) 0s linear infinite;
}

.top:hover .ticker {
  animation-play-state: paused !important;
}

@keyframes scroll-ltr {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes scroll-rtl {
  0% {
    transform: translate3d(-0, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

/* intro */

.IntroSwiper,
.mySwiper2 {
  width: 100%;
  height: 500px;
}

.IntroSwiper .swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
  right: 40px;
  align-items: center;
  /* top: 40%; */
}

.IntroSwiper .swiper-pagination-bullet {
  height: 10px;
  width: 5px;
  border-radius: 2.5px;
  background-color: var(--white);
  opacity: 1;
}

.IntroSwiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  transform: translateX(5px);
}

section.intro {
  max-width: 1500px;
  margin: 0 auto;
}
.intro img {
  width: 100%;
  height: 500px;
  max-height: 100%;
  /* object-fit: cover; */
  object-position: center;
}

.annex {
}

.programe {
  padding-top: 60px;
}

/* .programe .line {
  margin: auto;
  width: fit-content;
} */

.programe .container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 40px;
  flex-wrap: wrap;
}

.programe .item {
  height: 300px;
  width: 300px;
  position: relative;
}

.programe .item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.programe .item p {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  background-color: #0007;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 17px;
}

.programe .item:hover p {
  height: 100%;
  transition: all 0.4s;
}

.mySwiper3 .swiper-slide {
  width: auto;
}

@media (max-width: 500px) {
  .mySwiper3 .swiper-slide {
    width: 100%;
  }
}

/* updates */

/* .updates .line {
  width: 250px;
} */

.updates {
  margin-top: 60px;
}

.updates .item:hover {
  opacity: 0.9;
  box-shadow: var(--primary) 0px 0px 9px 0px;
  transition-property: opcaity, box-shadow;
  transition-timing-function: ease;
  transition-duration: 350ms;
}

.updates .container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 40px;
  flex-wrap: wrap;
}
.container.btn-more {
  justify-content: end;
}

.updates .container .line {
  /* margin-right: 0; */
}

.updates .item {
  height: 300px;
  min-width: 30%;
  flex: 1;
  position: relative;
}
html[lang='fr'] .updates .item {
  direction: ltr;
}

.updates .item:first-child {
  flex: 2;
  min-width: 60%;
}

.updates .item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.updates .item p {
  position: absolute;
  bottom: 10px;
  right: 10px;
  left: 10px;
  text-align: center;
  background-color: #985931e0;
  padding: 10px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 17px;
}

@media (max-width: 800px) {
  .IntroSwiper {
    max-height: 300px;
  }
  .updates .item {
    min-width: 40%;
  }
}

@media (max-width: 500px) {
  .updates .item {
    min-width: 100%;
  }
}

/* region */

.region .container:nth-child(2) {
  justify-content: space-between;
}

.region .map {
  max-width: 1000px;
  width: 100%;
  display: flex;
  position: relative;
}

.region .map .img {
  max-width: 60%;
}

.region .map .img:last-child {
  max-width: 70%;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 36%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.s0 {
  cursor: pointer;
}

.its-info {
  display: none;
}

.its-info.active {
  display: block !important;
}

.contents-svg {
  pointer-events: none;
}

.region .counts {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 2px;
}

.region .counts .count {
  background-color: var(--primary);
  color: #fff;
  padding: 5px 10px;
  text-align: center;
  font-weight: 600;
  direction: ltr;
  white-space: nowrap;
}

.region .counts .count span {
  display: block;
  text-align: center;
  width: 100%;
  font-weight: 600;
  color: #fa0;
  white-space: nowrap;
}

.region .container.imgs {
  display: flex;
  gap: 0;
}

.region .container.imgs img {
  width: 250px;
  height: 300px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 500px) {
  .region .container.imgs img {
    width: 100%;
    height: 200px;
  }
}

@media (max-width: 1200px) {
  .region .map {
    max-width: 800px;
  }
  .region .map .img:last-child {
    left: 35%;
  }
  .region .container:nth-child(2) {
    justify-content: center;
    align-items: center;
  }
  .region .counts {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .region .counts .count {
    flex: 1;
  }
}

@media (max-width: 1210px) {
  .region .container:nth-child(2) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .region .counts {
    flex-direction: row;
  }
}
.info .border {
  margin-top: 00px;
  margin-top: 60px;
  border: #985931 2px dashed;
}

/* .info .line {
  width: 200px;
} */

html[lang='fr'] .info {
  direction: ltr;
}
.info .tabs {
  display: flex;
  gap: 40px;
  justify-content: center;
  margin: auto;
  width: 600px;
  max-width: 90%;
}

.info .tabs .tab {
  flex: 1;
  border: solid 2px #985931;
  text-align: center;
  padding: 2px 0px;
  color: #985931;
  font-weight: 500px;
  cursor: pointer;
  margin-top: -15px;
  background-color: white;
}

.info .tabs .tab.active {
  background-color: #985931;
  color: #fff;
}

.info table {
  border-collapse: separate;
  width: 100%;
  margin-top: 40px;
  border-spacing: 5px;
}

.info table td {
  border: solid 2.5px #985931;
  border-radius: 5px;
  min-height: 60px;
  height: 100%;
}

.info table .title {
  width: 100%;
  background-color: #985931;
  color: #fff;
  text-align: center;
}

.info table td:first-child {
  width: 90px;
  text-align: center;
  padding: 0;
}

.info table td {
  padding: 10px;
  vertical-align: top;
}

.info table td div {
  text-align: center;
  margin-top: 0;
}

.table-pagination {
  width: 200px;
  direction: ltr;
  display: flex;
  margin: 20px auto;
  border: solid 2px #985931;
  justify-content: space-between;
  height: 35px;
  align-items: center;
}

.table-pagination button {
  width: 60px;
  display: flex;
  justify-content: center;
  border: none;
  cursor: pointer;
  background: transparent;
}

.table-pagination div {
  border-left: 1px #985931 solid;
  border-right: 1px #985931 solid;
  flex: 1;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

@media (max-width: 500px) {
  .info .tabs {
    gap: 10px;
  }
}

/* media */

/* .media .line {
  width: 300px;
  white-space: nowrap;
} */

.media .container {
  display: flex;
  gap: 40px;
}

.media .item {
  flex: 1;
  width: calc(50% - 100px);
  margin-top: 40px;
}

@media (max-width: 800px) {
  .media .container {
    flex-direction: column;
  }
  .media .item {
    width: 100%;
  }
}

.media .item h3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

.media .item .mySwiper2,
.media .item iframe {
  width: 100% !important;
  height: 320px !important;
}
div.LazyLoadYouTube {
  width: auto !important;
  height: unset !important;
  /* position: unset !important; */
}
.media .item .mySwiper2 img,
.media .item iframe {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.swiper-horizontal .swiper-pagination-bullets {
  top: 95%;
  width: 100%;
  position: absolute;
}

.mySwiper2 .swiper-pagination-bullet {
  height: 8px;
  width: 8px;
  background-color: #555;
  border-radius: 50%;
}

.swiper-horizontal .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: white;
  border: solid 1px #555;
}

.mySwiper2 .swiper-button-prev,
.mySwiper2 .swiper-button-next {
  height: 35px;
  width: 35px;
  background-color: #fffa;
  border-radius: 50%;
  font-size: 10px;
}

.mySwiper2 .swiper-button-prev::after,
.mySwiper2 .swiper-button-next::after {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.news {
  height: 300px;
  width: 100%;
  display: flex;
  background-size: cover;
  background-position: center;
  margin-top: 40px;
  position: relative;
}

.news .bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #fffc;
}

.news .container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.news .container h2 {
  font-size: 40px;
  font-weight: bold;
  color: var(--primary);
}

.news form {
  margin-top: 20px;
  display: flex;
  width: 100%;
  max-width: 800px;
  flex-direction: row-reverse;
}

.news form button {
  background: #985931;
  color: white;
  height: 40px;
  width: 80px;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.news form input {
  flex: 1;
  text-align: left;
  padding-left: 10px;
  outline: none;
}

footer {
  background-color: #985931;
  color: white;
  padding-top: 40px;
}
html[lang='fr'] footer {
  direction: ltr;
}

footer .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
}

footer .container h4 {
  font-size: 18px;
  margin-bottom: 6px;
  position: relative;
  padding-bottom: 4px;
  font-weight: 600;
}

footer .container h4::after {
  content: '';
  height: 2px;
  width: 60px;
  background-color: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
}

html[lang='fr'] footer .container h4::after {
  right: none;
  left: 0;
}

footer .container .item p {
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 14px;
}

.item .social {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.item .social button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  color: #985931;
  cursor: pointer;
}

.item .social button svg {
  min-height: 16px;
  max-width: 16px;
}

.copyright {
  margin-top: 40px;
  border-top: #fff 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.copyright p {
  text-align: center;
}

.show-more {
  color: var(--primary);
  text-decoration: none;
  font-weight: 600;
  margin-right: auto;
  display: flex;
  gap: 10px;
  align-items: center;
}

.show-more svg {
  height: 30px;
  width: auto;
}

@media (max-width: 500px) {
  footer .container {
    flex-direction: column;
  }
}

/* TitleSection */
html[lang='fr'] .TitleSection {
  direction: ltr;
}
html[lang='fr'] .TitleSection .icon {
  transform: rotate(180deg);
}
.TitleSection {
  height: 200px;
  background: linear-gradient(var(--white) 0%, var(--primary) 40%);
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
  text-align: center;
}

.TitleSection h1 {
  font-size: 45px;
  color: white;
  font-weight: 600;
  line-height: 1;
}

.TitleSection .page-url {
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 16px;
  align-items: center;
  gap: 5px;
  justify-content: center;
  flex-wrap: wrap;
}

.TitleSection .page-url a {
  font-weight: 600;
}

@media (max-width: 500px) {
  .TitleSection h1 {
    font-size: 35px;
  }
  .TitleSection .page-url {
    font-size: 13px;
  }
}
@media (max-width: 425px) {
  .TitleSection h1 {
    font-size: 30px;
  }
  .TitleSection .page-url {
    font-size: 12px;
  }
}

/* Monography */

.info.container {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 30px;
}

html[lang='fr'] .info.container {
  direction: ltr;
}

.CardVideo {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  flex-wrap: wrap;
  align-items: end;
  justify-content: center;
}

.CardVideo iframe {
  /* height: 300px; */
  width: 100%;
  aspect-ratio: 4/3;
  /* max-width: 70%; */
  flex: 2;
  object-fit: cover;
}
@media (max-width: 950px) {
  .CardVideo {
    flex-direction: column;
    align-items: center;
  }
}

.CardVideo .Card-body {
  flex: 3;
}
.CardVideo .Card-body .line {
  padding-top: 0;
  padding-bottom: 0;
}

html[lang='fr'] .Monography {
  direction: ltr;
}
.Monography .container.info-numbers {
  min-height: 200px;
}

.Monography .grid {
  display: flex;
  /* flex-direction: row-reverse; */
  flex-wrap: wrap;
  gap: 10px;
  margin: 40px 0;
}

.Monography .grid .item {
  height: 160px;
  flex: 1;
  min-width: max(200px, calc(33% - 10px));
  background-color: #e6e6e6;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition-property: background-color, color, filter;
  transition-duration: 350ms;
  transition-timing-function: ease-in-out;
}

.Monography .grid .item img {
  height: 80px;
  width: 80px;
  object-fit: contain;
}

.Monography .grid .item h5 {
  font-size: 18px;
  font-weight: bold;
  margin-top: -10px;
}
.Monography .grid .item:hover {
  cursor: pointer;
  background-color: var(--primary);
}
.Monography .grid .item:hover img {
  filter: hue-rotate(0deg) saturate(0%) brightness(300%);
}
.Monography .grid .item:hover h5 {
  color: #fcfcfc;
}

.CardImg {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  max-width: 100%;
}

.CardImg img {
  flex: 2;
  display: flex;
  max-width: 100%;
  width: 300px;
  object-fit: cover;
}

.CardImg p {
  font-size: 16px;
  object-fit: cover;
  flex: 3;
  min-width: min(100%, 300px);
}
html[lang='fr'] .CardImg p {
  direction: ltr;
}

@media (max-width: 500px) {
  .Monography .grid .item {
    min-width: max(160px, calc(33% - 10px));
  }
}

@media (max-width: 500px) {
  .region .container.imgs img {
    width: 100%;
    /* height: 200px; */
  }
  .region .map .img {
    max-width: 90%;
  }
  .region .map {
    flex-direction: column;
    align-items: center;
  }
  .region .map .img:last-child {
    max-width: 90%;
    height: 100%;
    width: 100%;
    position: relative;
    left: 0%;
    top: 0%;
    transform: none;
  }
}
